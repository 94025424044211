import React from "react"
import { Box, Grid, Calendar as Cal, Button } from "grommet"
import { getDaysInMonth, format, parseISO } from "date-fns"
import { dateToFourPillar } from "../../cm-lib/FourPillar/FourPillar"
import { get12Officer } from "../../cm-lib/DateSelection/index"
import { DATE_FORMAT } from "../../constants/"

const Header = ({ children }) => (
  <Box align="center" background="accent-2">
    {" "}
    {children}{" "}
  </Box>
)

const NColumnGrid = ({ dateObject = new Date(), onRenderItem }) => {
  dateObject.setDate(1)
  const numDaysMonth = getDaysInMonth(dateObject)

  const dayOfWeek = dateObject.getDay()
  console.log(`${numDaysMonth} ${dateObject}`)
  const Calendar = [...Array(35).keys()].map((e, i) => {
    if (i >= dayOfWeek && i - dayOfWeek < numDaysMonth) {
      dateObject.setDate(i - (dayOfWeek - 1))
      return {
        date: format(dateObject, "d"),
        dateString: format(dateObject, DATE_FORMAT),
      }
    } else {
      return {
        date: "",
      }
    }
  })
  console.log(Calendar)
  return (
    <>
      {dateObject && (
        <Grid
          columns={{
            count: 7,
            size: "auto",
          }}
        >
          <Header> Sun </Header>
          <Header> Mon </Header>
          <Header> Tue </Header>
          <Header> Wed </Header>
          <Header> Thur </Header>
          <Header> Fri </Header>
          <Header> Sat </Header>

          {Calendar.map((e, i) => {
            const dateObject = e.dateString && parseISO(e.dateString)
            const bazi = dateObject && dateToFourPillar(dateObject)
            const officer =
              bazi &&
              get12Officer(bazi.month.substr(1, 1), bazi.day.substr(1, 1))
            return (
              <Box key={i} pad="small" height="120px" background="brand">
                {dateObject && (
                  <>
                    <Box> {dateObject && format(dateObject, "d")}</Box>
                    <Box> {bazi && bazi.day} </Box>
                    <Box> {bazi && officer.chineseName} </Box>
                    <Box>{onRenderItem && onRenderItem(e.dateString, i)} </Box>
                  </>
                )}
              </Box>
            )
          })}
        </Grid>
      )}
    </>
  )
}

export default NColumnGrid
