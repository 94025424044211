import React, { useState, useEffect } from "react"
import Layout from "../../../containers/layout"
import Calendar from "../../../containers/Calendar"
import Ad from "../../../containers/Ad"
import { Anchor, Button, Box, Layer, CheckBox, Text } from "grommet"
import {
  addMonths,
  subMonths,
  format,
  parseISO,
  getDaysInMonth,
  setSeconds,
  setMilliseconds,
} from "date-fns"
import DatePicker from "../../../components/DateTimePicker"
import {
  setStorage,
  getStorage,
  assign,
  getQueryString,
  parseStringDate,
} from "../../../helpers/index"
import {
  get3VWithinRange,
  lookupChart,
  getMonthChartByDate,
  getDayChartByDate,
  isDivineLightDay,
  getZhiRunHourChart,
  isDivineBlessingDay,
  isDivineForceDay,
  findChiefPalace,
} from "../../../cm-lib/QiMen"
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  READABLE_DATETIME_FORMAT,
} from "../../../constants"
const STORAGE_KEY = "Profile"
const queryString = require("query-string")

const Home = () => {
  const qs = getQueryString()
  const thisMonth = (qs.search && parseISO(qs.search)) || new Date()
  const profilesString = getStorage(STORAGE_KEY)
  const ap = parseStringDate(qs.dt, null)
  const [month, setMonth] = useState(thisMonth)
  const [showModal, setShowModal] = useState(false)
  const [saveDOB, setSaveDOB] = useState(false)
  const [profiles, setProfiles] = useState(JSON.parse(profilesString) || [])
  const [activeProfile, setActiveProfile] = useState(ap)
  const [threeVictories, setThreeVictories] = useState(
    ap &&
      get3VWithinRange(
        qs.dt,
        format(month, DATETIME_FORMAT),
        getDaysInMonth(thisMonth)
      )
  )

  const nextMonthQS = {
    ...qs,
    search: format(addMonths(new Date(month.getTime()), 1), DATE_FORMAT),
  }
  const prevMonthQS = {
    ...qs,
    search: format(subMonths(new Date(month.getTime()), 1), DATE_FORMAT),
  }
  return (
    <Layout max="100%">
      <Box direction="row">
        <Button
          primary
          label="Previous"
          onClick={() => assign("?" + queryString.stringify(prevMonthQS))}
        />
        <Button
          primary
          label="Next"
          onClick={() => assign("?" + queryString.stringify(nextMonthQS))}
        />
        <Button
          primary
          label="Personalize"
          onClick={() => setShowModal(true)}
        />
      </Box>
      <Box>
        <h2>{format(month, "yyyy MMM")} Calendar </h2>
        {!!activeProfile && (
          <Text>DOB {format(activeProfile, READABLE_DATETIME_FORMAT)}</Text>
        )}
      </Box>
      <Calendar
        dateObject={new Date(month.getTime())}
        onRenderItem={
          ap
            ? (dateString, i) => {
                const tv = threeVictories[i]
                return (
                  <>
                    <Box> {tv && tv.isDivineLightDate && "DL"}</Box>
                    <Box> {tv && tv.isDivineLight3Overlap && "DL - OL"}</Box>
                    <Box> {tv && tv.isDivineBlessDate && "DB"}</Box>
                    <Box> {tv && tv.isDivineForceDate && "DF"}</Box>
                  </>
                )
              }
            : false
        }
      />
      {showModal && (
        <Layer
          onEsc={() => setShowModal(false)}
          onClickOutside={() => setShowModal(false)}
        >
          <Box pad="small">
            <DatePicker
              label="Personalize"
              onClick={date => {
                const dateString = format(date, DATETIME_FORMAT)
                if (saveDOB && profiles.indexOf(dateString) === -1) {
                  const obj = profiles.concat([dateString])
                  setProfiles(obj)
                  setStorage(STORAGE_KEY, JSON.stringify(obj))
                }
                const currentQS = getQueryString()
                assign(
                  "?" +
                    queryString.stringify({
                      ...qs,
                      dt: dateString,
                    })
                )
              }}
            />
            {profiles.map((e, index) => (
              <Box direction="row">
                <Anchor href={`?dt=${e}`}>
                  {format(parseISO(e), DATETIME_FORMAT)}
                </Anchor>
                <Anchor
                  onClick={e => {
                    const obj = profiles.filter((o, i) => !(i === index))
                    setProfiles(obj)
                    setStorage(STORAGE_KEY, JSON.stringify(obj))
                  }}
                >
                  {" "}
                  x{" "}
                </Anchor>
              </Box>
            ))}
            <CheckBox
              checked={saveDOB}
              label="Save the DOB on this device?"
              onChange={event => setSaveDOB(event.target.checked)}
            />
          </Box>
        </Layer>
      )}
      <Ad />
    </Layout>
  )
}

export default Home
