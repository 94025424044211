import LunarCalendar from "lunar-calendar"
import { calculateHour } from "../FourPillar/"

export const dateToFourPillar = date => {
  const month = date.getMonth() + 1
  const calendar = LunarCalendar.calendar(
    date.getFullYear(),
    month,
    date.getDate()
  )
  const found = calendar.monthData.find(
    e =>
      e.year === date.getFullYear() &&
      e.month === month &&
      e.day === date.getDate()
  )
  return {
    year: found && found.GanZhiYear,
    month: found && found.GanZhiMonth,
    day: found && found.GanZhiDay,
    hour: found && calculateHour(found.GanZhiDay.substr(0, 1), date.getHours()),
  }
}
